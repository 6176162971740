export const getBackgroundImages = async () => {
    return [
      '/backgrounds/main.jpg',
      '/backgrounds/background1.jpg',
      '/backgrounds/background2.jpg',
      '/backgrounds/background3.jpg',
      '/backgrounds/background4.jpg',
      '/backgrounds/background5.jpg',
      '/backgrounds/background6.jpg'
    ];
  };
  
  export const getImageDimensions = (url: string): Promise<{ width: number; height: number }> => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        resolve({
          width: img.width,
          height: img.height
        });
      };
      img.src = url;
    });
  };
  
  export const calculateBackgroundStyle = (
    imageWidth: number,
    imageHeight: number,
    screenWidth: number,
    screenHeight: number
  ) => {
    return {
        backgroundImage: '',  // Will be set separately
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100vw',
        height: '100vh',
        zIndex: 0,
        minHeight: '100vh',
        overflow: 'hidden'
    };
  };